<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Items
        <v-spacer></v-spacer>
        <!--
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>-->
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pa-5">
          <ItemModalForm
            :modalData="modalData"
            :editedItemId="editedItemId"
            :permissions="permissions"
            :categories="categoryCollection"
            :webCategories="webCategoryCollection"
            :quantityUnitCollection="quantityUnitCollection"
            :priceListCollection="priceListCollection"
            :itemCollection="itemCollection"
            :itemCollectionWithDefaultQuantities="
              itemCollectionWithDefaultQuantities
            "
            :statuses="statuses"
            :defaultTab="0"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
            @handleDeleteItem="handleDeleteItem"
          ></ItemModalForm>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="filterableCollection"
        :search="filterData.search"
        :sort-by.sync="sortBy"
        @click:row="handleClickItem"
        @update:sort-by="handleSortByChange"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th colspan="2">
                <v-text-field
                  v-model="filterData.search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </th>
              <th>
                <v-select
                  v-model="categoryNameFilter"
                  :items="[{ category: 'Select' }, ...categoryNameCollection]"
                  item-text="category"
                  item-value="category"
                ></v-select>
              </th>
              <th></th>
              <th>
                <p style="margin-bottom: -16px">Can be sold</p>
                <v-checkbox v-model="filterData.sale"> </v-checkbox>
              </th>
              <th>
                <p style="margin-bottom: -16px">Can be manufactured</p>
                <v-checkbox v-model="filterData.manufacture"> </v-checkbox>
              </th>
              <th>
                <p style="margin-bottom: -16px">Can be purchased</p>
                <v-checkbox v-model="filterData.purchase"> </v-checkbox>
              </th>
              <th>
                <p style="margin-bottom: -16px">Service</p>
                <v-checkbox v-model="filterData.service"> </v-checkbox>
              </th>
              <th>
                <v-btn
                  class="mx-1"
                  fab
                  dark
                  small
                  color="pink"
                  @click="filterData = Object.assign({}, initialFilterData)"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.sale="{ item }">
          <v-simple-checkbox v-model="item.sale" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.manufacture="{ item }">
          <v-simple-checkbox
            v-model="item.manufacture"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.purchase="{ item }">
          <v-simple-checkbox
            v-model="item.purchase"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.service="{ item }">
          <v-simple-checkbox
            v-model="item.service"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.inventory.item[item.status].value
          }}</span>
        </template>

        <!-- <template v-slot:item.category_id="{ item }">
          <span v-if="item.category_id != null">
            {{ getCategoryTranslation(item.category_id) }}
          </span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/inventory/items/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <!-- <pre>
        {{ filterableCollection }}
      </pre> -->
      <!-- {{ permissions }} -->
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import ItemModalForm, { initialFormData } from "./ItemModalForm";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "inventory/item/";

const initialFilterData = {
  search: "",
  purchase: false,
  sale: false,
  manufacture: false,
  service: false,
};

export default {
  name: "items",
  components: { ItemModalForm },
  data() {
    return {
      loader: false,
      appLocale: i18nService.getActiveLanguage(),
      showAll: false,
      search: "",
      categoryNameFilter: "Select",
      filterData: initialFilterData,
      initialItems: [],
      // filterableCollection: this.itemCollection,
      collection: [],
      permissions: [],
      headers: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Item name",
          value: "name",
        },
        {
          text: "Item category",
          value: "category",
          filter: (value) => {
            if (this.categoryNameFilter == "Select") return true;
            return value == this.categoryNameFilter;
          },
        },
        { text: "Status", value: "status" },
        {
          text: "Can be sold",
          value: "sale",
          searchable: false,
        },
        {
          text: "Can be manufactured",
          value: "manufacture",
          searchable: false,
        },
        {
          text: "Can be purchased",
          value: "purchase",
          searchable: false,
        },
        {
          text: "Service",
          value: "service",
          searchable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      sortBy: "name",

      modalData: {
        dialog: false,
        loading: false,
        // editedItem: Object.assign({}, initialFormData),
      },
      // editedItem: Object.assign({}, initialFormData),
      editedItemId: null,

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([
      "itemCollection",
      "statuses",
      "categoryCollection",
      "webCategoryCollection",
      "quantityUnitCollection",
      "priceListCollection",
      // "currencyCollection",
    ]),
    categoryNameCollection() {
      return this.filterableCollection.map((item) => {
        return item.category;
      });
    },

    filterableCollection() {
      this.itemCollection.map((item) => {
        item.category = this.getCategoryTranslation(item.category_id);
        return item;
      });
      if (
        !this.filterData.manufacture &&
        !this.filterData.purchase &&
        !this.filterData.sale &&
        !this.filterData.service
      ) {
        return this.itemCollection;
      } else {
        return this.itemCollection.filter((item) => {
          let activeItem = false;
          if (this.filterData.manufacture && item.manufacture) {
            activeItem = true;
          }
          if (this.filterData.service && item.service) {
            activeItem = true;
          }
          if (this.filterData.sale && item.sale) {
            activeItem = true;
          }
          if (this.filterData.purchase && item.purchase) {
            activeItem = true;
          }
          return activeItem;
        });
      }
    },

    itemCollectionWithDefaultQuantities() {
      return this.itemCollection.filter((item) => {
        return item.default_quantities.length > 0;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editedItemId = id;
            this.modalData.dialog = true;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "fetchItem",
      "fetchCategory",
      "fetchWebCategory",
      "fetchQuantityUnit",
      "fetchPriceList",
    ]),

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleDeleteItem(item) {
      this.deleteItem(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchItem();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.editedItemId = null;
      this.fetchItem();
      this.editedItem = Object.assign({}, initialFormData());
      if (this.$route.name !== "items") this.$router.push({ name: "items" });
    },

    closeDelete() {
      this.editedItem = Object.assign({}, initialFormData());
      this.dialogDelete = false;
    },

    handleSaveModalForm(item = null) {
      if (item) {
        this.handleClickItem(item);
      }

      // this.handleCloseModalForm();
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/inventory/items/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name === "inventory.item." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("item") > -1
        );
      }
    },

    getCategoryTranslation(incoming_id) {
      var catname;
      let appLocale = i18nService.getActiveLanguage();
      catname = this.categoryCollection.find((x) => x.id == incoming_id);
      if (catname != undefined) {
        return catname.translations[appLocale]
          ? catname.translations[appLocale].name
          : catname.translations[Object.keys(catname.translations)[0]].name;
      } else {
        return "";
      }
    },
    getWebCategoryTranslation(incoming_id) {
      var catname;
      let appLocale = i18nService.getActiveLanguage();
      catname = this.webCategoryCollection.find((x) => x.id == incoming_id);
      if (catname != undefined) {
        return catname.translations[appLocale]
          ? catname.translations[appLocale].name
          : catname.translations[Object.keys(catname.translations)[0]].name;
      } else {
        return "";
      }
    },
    handleSortByChange(newSortBy) {
      this.sortBy = newSortBy;
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Item" }]);
    // this.fetchItem();
    this.fetchCategory();
    this.fetchWebCategory();
    this.fetchQuantityUnit();
    this.fetchPriceList();
    this.fetchItem();
    // this.fetchCurrency();
    this.setPermissions();
    // this.$nextTick(() => {
    //   this.filterableCollection = this.itemCollection;
    // });
  },
};
</script>
