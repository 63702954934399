export const customFieldMixins = {
  methods: {
    initTranslatedCustomFields() {
      // console.log("initTranslatedCustomFields");
      this.languages.forEach((language) => {
        this.initialTransaltedCustomFields.forEach((attribute) => {
          if (!this.formModel.translations[language.lang]["custom_fields"]) {
            this.formModel.translations[language.lang]["custom_fields"] = {};
          }
          if (
            !this.formModel.translations[language.lang]["custom_fields"][
              attribute
            ]
          ) {
            this.formModel.translations[language.lang]["custom_fields"][
              attribute
            ] = "";
          }
        });
      });
    },

    handleChangeCustomFieldValue(customField, newValue) {
      if (
        this.initialFormData().custom_fields &&
        Object.keys(this.initialFormData().custom_fields).indexOf(
          customField
        ) != -1
      ) {
        this.formModel.custom_fields[customField] = newValue;

        return true;
      }

      if (
        this.initialTransaltedCustomFields.indexOf(customField) != -1 &&
        this.formModel.translations[this.selectedLocale.lang]
      ) {
        this.formModel.translations[this.selectedLocale.lang].custom_fields[
          customField
        ] = newValue;
      } else {
        this.formModel.translations[this.selectedLocale.lang][customField] =
          newValue;
      }
      let formModel = this.formModel;
      this.formModel = null;
      this.formModel = formModel;
    },
  },
};
