<template>
  <div>
    <div v-if="config">
      <div v-if="config.type == 'accordion'">
        <AccordionCustomFieldComponent
          :accordions="customFieldValue || []"
          :config="config"
          :label="$t(config.label)"
          @handleChangeValue="handleChangeValue"
        ></AccordionCustomFieldComponent>
      </div>

      <div v-if="config.type == 'text'">
        <v-text-field
          v-model="customFieldValue"
          @input="handleChangeValue"
        >
          <template #label>
            <span>
              {{ $t(config.label) }} 
              <span v-if="maxLength" 
                :class="{
                  'length-warning-red': inputTextLength > maxLength,
                  'length-warning-orange': inputTextLength <= maxLength && inputTextLength > 120,
                  'background-white': inputTextLength <= maxLength && inputTextLength <= 120
                }"
              >
                {{ ' (' + inputTextLength + '/' + maxLength + ')' }}
              </span>
            </span>
          </template>
        </v-text-field>
      </div>

      <div v-if="config.type == 'textarea'">
        <v-textarea
          outlined
          :value="customFieldValue"
          @input="handleChangeValue"
        >
          <template #label>
            <span>
              {{ $t(config.label) }} 
              <span v-if="maxLength" 
                :class="inputTextLength > maxLength
                  ? 'length-warning-red'
                  : (maxLength == 150 && inputTextLength > 120)
                    ? 'length-warning-orange'
                    : 'background-white'"
                  >
                {{ ' (' + inputTextLength + '/' + maxLength + ')' }}
              </span>
            </span>
          </template>
        </v-textarea>
      </div>

      <div v-if="config.type == 'ckeditor'">
        {{ $t(config.label) }}
        <ckeditor
          v-model="customFieldValue"
          @input="handleChangeValue"
          :config="editorConfig"
        ></ckeditor>
      </div>

      <div v-if="config.type == 'multi_checkbox'">
        <v-checkbox
          class="mt-0"
          v-for="(checkBox, index) in config.items"
          v-bind:key="index"
          v-model="customFieldValue"
          :label="$t(checkBox.label)"
          :value="checkBox.value"
          @change="handleChangeValue"
        ></v-checkbox>
      </div>

      <div v-if="config.type == 'multi_select'">
        <v-select
          v-model="customFieldValue"
          :items="config.items"
          :label="$t(config.label)"
          :multiple="config.multiple"
          :hint="config.hint"
          @change="handleChangeValue"
          chips
          persistent-hint
        >
          <template #selection="{ item }">
            <v-chip
              color="bg-gray-100"
              close
              @click:close="deleteChip(item, customFieldValue)"
              >{{ item }}</v-chip
            >
          </template>
        </v-select>
      </div>
      <div v-if="config.type == 'combo_box'">
        <v-col cols="12">
          <v-combobox
            v-model="customFieldValue"
            :items="comboBoxItems"
            :label="$t(config.label)"
            :multiple="config.multiple"
            :hint="config.hint"
            :item-text="config.itemText"
            :item-value="config.itemValue"
            return-object
            @change="handleChangeValue"
            clearable
            chips
          ></v-combobox>
        </v-col>
      </div>
      <v-radio-group
        v-if="config.type == 'radio_group'"
        v-model="customFieldValue"
        :row="config.row"
        @change="handleChangeValue"
      >
        <v-radio
          v-for="(radioBtn, index) in config.items"
          :key="index"
          :label="$t(radioBtn.label)"
          :value="radioBtn.value"
          >{{ index }}
        </v-radio>
      </v-radio-group>

      <v-menu
        v-if="config.type == 'date_range_picker'"
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="customFieldValue"
        transition="scale-transition"
        offset-y
        min-width="auto"
        @input="handleChangeDateRangePicker"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDates"
            :label="$t(config.label)"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="customFieldValue"
          :first-day-of-week="1"
          range
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(customFieldValue)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <div v-if="config.type == 'date_picker'">
        <label for="datepicker">{{ $t(config.label) }}</label>
        <b-form-datepicker
          id="datepicker"
          v-model="customFieldValue"
          class="mb-2"
          @context="onContext"
        ></b-form-datepicker>
      </div>

      <div v-if="config.type == 'media_selector'">
        <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="handleOpenMediaSelectModal(index)"
          :disabled="canAddFile"
        >
          {{ $t(config.selectButtonText) }}
          <v-icon right dark> mdi-cloud-upload </v-icon>
        </v-btn>

        <v-btn
          v-if="customFieldValue && customFieldValue.length > 1"
          class="mx-1"
          fab
          dark
          small
          color="pink"
          @click="handleDeleteMediaSelector"
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>

        <p v-if="config.resolution" class="ml-2">
          {{ $t("FORM_INPUT_NAMES.image_resolution") }}:
          <span class="primary--text">{{ config.resolution }}px</span>
        </p>

        <SelectMediaModalForm
          :modalData="mediaModalData"
          :config="config"
          @closeModalForm="handleCloseMediaModalForm"
          @saveModalForm="handleSaveMediaModalForm"
        >
        </SelectMediaModalForm>
        <vue-easy-lightbox
          :visible="vue_easy_lightbox_visible"
          :imgs="vue_easy_lightbox_imgs"
          :index="vue_easy_lightbox_index"
          @hide="handleVueEasyLightboxHide"
        ></vue-easy-lightbox>

        <div class="horizontal-scroll">
          <draggable
            tag="v-row"
            v-model="draggableCards"
            style="display: flex; flex-direction: row"
          >
            <v-col
              v-for="(file, index) in files"
              :key="file.path"
              :class="column_class"
            >
              <v-card>
                <v-img
                  v-if="previewable_mime_types.includes(file.mime_type)"
                  :src="getFile(file)"
                  height="175"
                  style="cursor: pointer"
                  :title="file.name"
                  @click="() => showImg(index)"
                ></v-img>
                <a v-else :href="getFile(file)" target="_blank">
                  <v-img
                    :src="getIcon(file)"
                    :title="file.name"
                    height="175"
                    contain
                  />
                </a>
                <v-card-actions style="height: 40px">
                  {{ file.name ? file.name.substring(0, 28) : "" }}
                  <v-spacer></v-spacer>
                  <v-icon
                    v-if="config.inputFields && config.inputFields.length > 0"
                    small
                    color="primary"
                    @click="handleEditCustomFieldInputs(file)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="primary" @click="handleInfoBox(file)">
                    mdi-information-outline
                  </v-icon>
                  <v-icon
                    small
                    color="primary"
                    @click="
                      deleteItem({
                        index: index,
                        type: 'FILE',
                        path: file.path,
                      })
                    "
                  >
                    mdi-delete
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </draggable>
        </div>
        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>

        <CustomInputsModalDialog
          v-if="customFieldInputData.dialog"
          :customFieldInputData="customFieldInputData"
          @handleCloseModalForm="handleCloseCustomInputModalForm"
          @handleSaveModalForm="handleSaveCustomInputModalForm"
          :calcLength="calcLength"
          :maxLength="maxLength"
        >
        </CustomInputsModalDialog>
      </div>
      <div v-if="config.type == 'external_references'">
        <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="handleAddExternalItem"
          :disabled="canAddFile"
        >
          {{ $t(config.selectButtonText) }}
          <v-icon right dark> mdi-plus-circle </v-icon>
        </v-btn>

        <SelectMediaModalForm
          :modalData="mediaModalData"
          :config="{
            maxFiles: 1,
          }"
          @closeModalForm="handleCloseMediaModalForm"
          @saveModalForm="handleSaveMediaModalFormItem"
        >
        </SelectMediaModalForm>

        <vue-easy-lightbox
          :visible="vue_easy_lightbox_visible"
          :imgs="vue_easy_lightbox_imgs"
          :index="vue_easy_lightbox_index"
          @hide="handleVueEasyLightboxHide"
        ></vue-easy-lightbox>

        <draggable
          tag="v-row"
          v-model="draggableCards"
          style="display: flex; flex-direction: row"
        >
          <v-col
            v-for="(file, index) in files"
            :key="file.path"
            :class="column_class"
          >
            <v-card>
              <v-img
                v-if="previewable_mime_types.includes(file.mime_type)"
                :src="getFile(file)"
                height="175"
                style="cursor: pointer"
                :title="file.name"
                @click="() => showImg(index)"
              ></v-img>
              <a v-else :href="getFile(file)" target="_blank">
                <v-img
                  :src="getIcon(file)"
                  :title="file.name"
                  height="175"
                  contain
                />
              </a>
              <v-card-actions style="height: 40px">
                {{ file.title ? file.title.substring(0, 28) : "" }}
                <v-spacer></v-spacer>
                <v-icon
                  small
                  color="primary"
                  @click="handleOpenMediaSelectModal(index)"
                >
                  mdi-cloud-upload
                </v-icon>
                <v-icon
                  v-if="config.inputFields && config.inputFields.length > 0"
                  small
                  color="primary"
                  @click="handleEditCustomFieldInputs(file)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small color="primary" @click="handleInfoBox(file)">
                  mdi-information-outline
                </v-icon>
                <v-icon
                  small
                  color="primary"
                  @click="
                    deleteItem({ index: index, type: 'FILE', path: file.path })
                  "
                >
                  mdi-delete
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
        </draggable>

        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>

        <CustomInputsModalDialog
          v-if="customFieldInputData.dialog"
          :customFieldInputData="customFieldInputData"
          @handleCloseModalForm="handleCloseCustomInputModalForm"
          @handleSaveModalForm="handleSaveCustomInputModalForm"
        >
        </CustomInputsModalDialog>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMediaModalForm from "@/view/media_library/SelectMediaModalForm.vue";
import { MIME_TYPE_ICONS } from "@/view/media_library/Browser.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import CustomInputsModalDialog from "@/view/components/CustomInputsModalDialog";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
import AccordionCustomFieldComponent from "./AccordionCustomFieldComponent";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
// import SiteService from "@/core/services/site.service.js";

const INITIAL_CUSTOM_FIELD_DATA = {
  dialog: false,
  file: null,
  inputFields: [],
};

const EXTERNAL_ITEM = {
  // path: "public/2/partnereink/nato_bazisok.jpg",
  path: "/public/external.jpg",
  name: "external.jpg",
  mime_type: "image/jpeg",
  size: 16743,
  created_at: "",
  user: 1,
  alt_text: "",
  title: "",
  icon: "",
  url: "",
};

export default {
  name: "CustomFieldComponent",
  props: ["config", "customFieldName", "value", "calcLength", "maxLength"],
  components: {
    SelectMediaModalForm,
    VueEasyLightbox,
    DeleteModalDialog,
    CustomInputsModalDialog,
    draggable,
    AccordionCustomFieldComponent,
  },
  data: () => ({
    customFieldValue: null,
    requiredRules: [(v) => !!v || "This field is required"],
    menu: false, // a date_range_pickerhez

    // selectMediaModalhoz
    mediaModalData: {
      dialog: false,
    },
    dialogDelete: false,
    editedItem: null,
    editedItemIndex: null,
    vue_easy_lightbox_visible: false,
    vue_easy_lightbox_index: 0,
    comboBoxItems: [],

    column_class: "col-4 col-md-3 col-lg-2 col-xl-2",
    previewable_mime_types: [
      "image/png",
      "image/jpeg",
      "image/x-ms-bmp",
      "image/webp",
      "image/gif",
      "image/svg+xml",
    ],

    customFieldInputData: INITIAL_CUSTOM_FIELD_DATA,
  }),

  computed: {
    computedAttachments() {
      return this.attachments.filter((item) => item.type == this.config.type);
    },

    draggableCards: {
      get() {
        return this.files;
      },
      set(val) {
        this.handleChangeValue(val);
      },
    },

    files() {
      // if (this.config.type != "media_selector" || !this.customFieldValue) {
      if (
        !(
          this.config.type == "media_selector" ||
          this.config.type == "external_references"
        ) ||
        !this.customFieldValue
      ) {
        return [];
      }

      return this.customFieldValue;
    },

    canAddFile() {
      return (
        this.config.maxFiles &&
        this.config.maxFiles <= this.files.length &&
        this.config.maxFiles != -1
      );
    },

    vue_easy_lightbox_imgs() {
      if (this.config.type != "media_selector" || !this.customFieldValue) {
        return [];
      }

      let previewArray = [];
      this.customFieldValue.forEach((item) => {
        if (this.previewable_mime_types.includes(item.mime_type)) {
          previewArray.push({ src: this.getFile(item), title: item.filename });
        }
      });

      return previewArray;
    },

    inputTextLength() {
      return this.value ? this.value.length : 0;
    },

    // a ckeditorhoz
    editorConfig() {
      return {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      };
    },

    // a date_range_pickerhez
    formattedDates() {
      if (!this.customFieldValue) return [];
      if (this.customFieldValue.length == 2) {
        return this.customFieldValue[0] + " - " + this.customFieldValue[1];
      }
      return this.customFieldValue;
    },
  },

  watch: {
    value: function (val) {
      this.customFieldValue = val;
    },
  },

  methods: {
    fetchComboBoxURL() {
      if (this.config.comboBoxItems) {
        this.comboBoxItems = this.config.comboBoxItems;
      } else {
        let appLocale = i18nService.getUserActiveLanguage();

        ApiService.get(this.config.itemsURL).then(({ data }) => {
          // create localized fields
          let dataLocalized = data.map((item) => {
            if (item.translations) {
              let translations = item.translations[appLocale];
              if (translations) {
                Object.keys(translations).forEach((property) => {
                  item[property] = item.translations[appLocale][property];
                });
                item.translated = true;
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key && "custom_fields" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            return item;
          });
          this.comboBoxItems = dataLocalized;
        });
      }
    },

    //date range picker
    handleChangeDateRangePicker() {
      this.handleChangeValue(this.customFieldValue);
    },

    //date picker
    onContext(ctx) {
      this.customFieldValue = ctx.selectedYMD;
      this.handleChangeValue(this.customFieldValue);
    },

    handleOpenMediaSelectModal(index) {
      this.editedItemIndex = index;
      this.mediaModalData.dialog = true;
    },

    handleCloseMediaModalForm() {
      this.editedItemIndex = null;
      this.mediaModalData.dialog = false;
    },

    handleSaveMediaModalForm(selectedFiles) {
      if (!this.customFieldValue) this.customFieldValue = [];

      selectedFiles.forEach((item) => {
        let add = true;
        this.customFieldValue.forEach((cFV) => {
          if (cFV.path == item.path) {
            add = false;
          }
        });
        if (add) {
          this.customFieldValue.push(item);
        }
      });

      this.handleChangeValue(this.customFieldValue);
      this.handleCloseMediaModalForm();
    },

    // handleSaveMediaModalFormItem(selectedFiles) {
    handleSaveMediaModalFormItem(selectedFiles) {
      if (!this.customFieldValue) this.customFieldValue = [];

      this.customFieldValue[this.editedItemIndex]["created_at"] =
        selectedFiles[0]["created_at"];
      this.customFieldValue[this.editedItemIndex]["mime_type"] =
        selectedFiles[0]["mime_type"];
      this.customFieldValue[this.editedItemIndex]["name"] =
        selectedFiles[0]["name"];
      this.customFieldValue[this.editedItemIndex]["path"] =
        selectedFiles[0]["path"];
      this.customFieldValue[this.editedItemIndex]["size"] =
        selectedFiles[0]["size"];
      this.customFieldValue[this.editedItemIndex]["user"] =
        selectedFiles[0]["user"];

      this.handleChangeValue(this.customFieldValue);
      this.handleCloseMediaModalForm();
    },

    showImg(index) {
      this.vue_easy_lightbox_index = index;
      this.vue_easy_lightbox_visible = true;
    },
    handleVueEasyLightboxHide() {
      this.vue_easy_lightbox_visible = false;
    },

    getIcon(file) {
      if (
        file.mime_type in MIME_TYPE_ICONS &&
        MIME_TYPE_ICONS[file.mime_type] != ""
      ) {
        return (
          "/media/media_library/mime_types/" + MIME_TYPE_ICONS[file.mime_type]
        );
      } else {
        return "/media/media_library/file-outline.svg";
      }
    },

    getFile(file) {
      return process.env.VUE_APP_BACKEND_URL + "/" + file.path;
    },

    getShortName(name) {
      if (name.length > 15) {
        name = name.substring(0, 14) + "...";
      }
      return name;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItemIndex = item.index;
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      this.customFieldValue.splice(this.editedItemIndex, 1);

      this.dialogDelete = false;
      this.editedItemIndex = null;
      this.handleChangeValue(this.customFieldValue);
    },

    closeDelete() {
      this.editedItem = null;
      this.editedItemIndex = null;
      this.dialogDelete = false;
    },

    handleChangeValue(val) {
      this.$emit("handleChangeCustomFieldValue", this.customFieldName, val);

      if (this.calcLength) {
        if (val) {
          this.inputTextLength = val.length
        } else {
          this.inputTextLength = 0
        }
      }
    },

    handleInfoBox(file) {
      Swal.fire({
        title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
        icon: "info",
        width: "600px",
        html:
          `<b>${this.$t("ALERT.created_at")}:</b> ` +
          file.created_at +
          "<br>" +
          `<b>${this.$t("ALERT.name")}:</b> ` +
          file.name +
          "<br>" +
          `<b>${this.$t("ALERT.mime_type")}:</b> ` +
          file.mime_type +
          "<br>" +
          `<b>${this.$t("ALERT.path")}:</b>
          <a href="` +
          this.getFile(file) +
          `" target="_blank">` +
          this.getFile(file) +
          `</a>` +
          "<br>" +
          `<b>${this.$t("ALERT.size")}:</b> ` +
          file.size +
          "<br>",
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#e33354",
        infoColor: "#e33354",
      });
    },

    handleEditCustomFieldInputs(file) {
      // if (!this.config.inputFields) this.config.inputFields = [];
      // this.customFieldInputData.inputFields = this.config.inputFields;
      // this.customFieldInputData.file = Object.assign({}, file);
      // this.customFieldInputData.dialog = true;
      this.customFieldInputData = null;
      this.customFieldInputData = {
        dialog: true,
        inputFields: this.config.inputFields,
        file: file,
      };
    },

    handleCloseCustomInputModalForm() {
      this.customFieldInputData.dialog = false;
    },

    handleSaveCustomInputModalForm(val) {
      if (!this.customFieldValue) {
        this.customFieldValue = [];
        this.customFieldValue.push(val);
      }
      this.handleChangeValue(this.customFieldValue);
      this.customFieldInputData.dialog = false;
      this.handleCloseCustomInputModalForm();
    },

    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    handleAddExternalItem() {
      if (!this.customFieldValue) this.customFieldValue = [];
      this.customFieldValue.push(Object.assign({}, EXTERNAL_ITEM));

      this.handleChangeValue(this.customFieldValue);
    },

    handleDeleteMediaSelector() {
      this.customFieldValue = null;
      this.handleChangeValue(this.customFieldValue);
    },
  },

  mounted() {
    if (this.config && this.config.initialColumnClass) {
      this.column_class = this.config.initialColumnClass;
    }

    if (this.config.itemsURL || this.config.comboBoxItems) {
      this.fetchComboBoxURL();
    }

    this.customFieldValue = this.value;
  },
};
</script>
<style scoped>
.horizontal-scroll {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.length-warning-red {
  color: #ff0000;
  background: white;
}
.length-warning-orange {
  color: #ffa515;
  background: white;
}
.background-white {
  background-color: white;
}
</style>
