<template>
  <v-dialog
    v-model="modalData.dialog"
    width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New item
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <span v-if="formModel.id">
              - {{ formModel.reference }} -
              {{ formModel.translations[selectedLocale.lang].name }}</span
            >
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <!-- <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="1" md="1">
            <v-btn
              v-if="permissionCan('delete') && formModel.deletable"
              class="mx-1"
              fab
              dark
              small
              color="pink"
              @click="deleteItem(formModel)"
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row> -->

        <v-form v-model="formValid" ref="form">
          <!-- <pre>
        {{ formModel }}
        </pre
          > -->
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-btn
                depressed
                color="primary"
                class="ma-2"
                v-if="!cloneItem && formModel.id"
                @click="handleCloneItem"
              >
                Clone item
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :id="dynamicID"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="1" md="1">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.inventory.item"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
            <v-col
              v-if="formModel.id && formModel.deletable"
              cols="12"
              sm="2"
              md="2"
            >
              <v-btn depressed color="error" @click="handleDeleteItem">
                Delete Item
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs vertical v-model="defaultTab">
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-settings </v-icon>
                  General
                </v-tab>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-cash-multiple </v-icon>
                  Price Lists
                </v-tab>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-format-list-bulleted </v-icon>
                  Components
                </v-tab>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-book-open </v-icon>
                  Links
                </v-tab>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-web </v-icon>
                  Web content
                </v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            :id="dynamicID"
                            v-model="formModel.reference"
                            label="Reference"
                            :rules="referenceRules"
                            :error-messages="messages['reference']"
                            @keyup="messages['reference'] = ''"
                            @input="handleChangeReference"
                          ></v-text-field>
                          <v-checkbox
                            v-model="formModel.sale"
                            color="primary"
                            label="Can be sold"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            :id="dynamicID"
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="
                              formModel.translations[selectedLocale.lang].name
                            "
                            label="Item name"
                            :rules="nameRules"
                            :error-messages="
                              messages[
                                'translations.' + selectedLocale.lang + '.name'
                              ]
                            "
                            @keyup="
                              messages[
                                'translations.' + selectedLocale.lang + '.name'
                              ] = ''
                            "
                          ></v-text-field>
                          <v-checkbox
                            v-model="formModel.manufacture"
                            color="primary"
                            label="Can be manufactured"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <!-- :multiple="true" -->
                          <treeselect
                            class="mt-4 mb-5"
                            :flat="true"
                            :options="categoryTree"
                            :default-expand-level="0"
                            placeholder="Category"
                            v-model="formModel.category_id"
                          />

                          <!--
                          <v-select
                            v-model="formModel.category_id"
                            :items="categories"
                            item-text="name"
                            item-value="id"
                            label="Category"
                            :id="dynamicID"
                          ></v-select> -->
                          <!-- <pre>{{ categories }}</pre> -->
                          <v-checkbox
                            class="mt-5 pt-5"
                            v-model="formModel.purchase"
                            color="primary"
                            label="Can be purchased"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            v-model="formModel.quantity_unit_id"
                            :items="quantityUnitCollection"
                            item-text="name"
                            item-value="id"
                            label="Quantity unit"
                            :rules="requiredRules"
                            :id="dynamicID"
                          ></v-select>
                          <v-checkbox
                            v-model="formModel.service"
                            color="primary"
                            label="Service"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="!formModel.service"
                        >
                          <div class="d-flex flex-row">
                            <div>
                              <!-- :items="itemInputData.item.default_quantities" -->
                              <!-- v-if="itemInputData" -->
                              <v-text-field
                                v-model="formModel.unit_weight"
                                type="number"
                                min="0"
                                label="Unit weight"
                                :id="dynamicID"
                              >
                              </v-text-field>
                            </div>
                            <div class="pa-1 mt-4" style="white-space: nowrap">
                              kg
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="formModel.manufacture"
                        >
                          <div class="d-flex flex-row">
                            <div>
                              <!-- :items="itemInputData.item.default_quantities" -->
                              <!-- v-if="itemInputData" -->
                              <v-text-field
                                v-model="formModel.standard_time"
                                type="number"
                                min="0"
                                label="Standard hours"
                                :id="dynamicID"
                              >
                              </v-text-field>
                            </div>
                            <div class="pa-1 mt-4" style="white-space: nowrap">
                              Hour
                            </div>
                          </div>
                        </v-col>

                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="4" md="4">
                          <v-autocomplete
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="formModel.cutting_id"
                            :items="geCuttingItems()"
                            label="Related cutting service"
                            item-text="name"
                            item-value="id"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <!-- Related items -->
                          <!-- <v-autocomplete
                            v-model="formModel.custom_fields.related_items"
                            :items="itemCollection"
                            chips
                            clearable
                            label="Related items"
                            multiple
                            prepend-icon="mdi-filter-variant"
                            solo
                            :error-messages="messages['related_items']"
                            @keyup="messages['related_items'] = ''"
                            item-text="ReferenceName"
                            item-value="id"
                          >
                            <template v-slot:selection="{ attrs, item }">
                              <v-chip
                                v-bind="attrs"
                                close
                                @click:close="removeRelated(item)"
                              >
                                <strong>{{ item.ReferenceName }}</strong
                                >&nbsp;
                              </v-chip>
                            </template>
                          </v-autocomplete> -->

                          <v-autocomplete
                            v-model="formModel.custom_fields.related_items"
                            :items="itemCollection"
                            label="Related items"
                            :rules="requiredRules"
                            multiple
                            small-chips
                            item-text="ReferenceName"
                            item-value="id"
                          >
                            <template #selection="data">
                              <draggable
                                :id="data.index"
                                :list="formModel.custom_fields.related_items"
                                v-bind="dragOptionsChips"
                                :move="move"
                                @change="change"
                              >
                                <v-chip
                                  draggable
                                  v-model="
                                    formModel.custom_fields.related_items[
                                      data.index
                                    ]
                                  "
                                  :key="data.item.id"
                                  @mousedown.stop
                                  @click.stop
                                >
                                  {{ data.item.ReferenceName }}
                                </v-chip>
                              </draggable>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- <pre>
                          {{ itemCollection }}
                        </pre> -->
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          Default quantities
                          <v-combobox
                            v-model="formModel.default_quantities"
                            chips
                            clearable
                            label="Default quantities"
                            multiple
                            prepend-icon="mdi-filter-variant"
                            solo
                            :error-messages="messages['default_quantities']"
                            @keyup="messages['default_quantities'] = ''"
                          >
                            <template v-slot:selection="{ attrs, item }">
                              <v-chip
                                v-bind="attrs"
                                close
                                @click:close="remove(item)"
                              >
                                <strong>{{ item }}</strong
                                >&nbsp;
                                <!-- <span>(interest)</span> -->
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="
                              formModel.translations[selectedLocale.lang]
                                .description
                            "
                            label="Description"
                            :error-messages="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.description'
                              ]
                            "
                            @keyup="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.description'
                              ] = ''
                            "
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="
                              formModel.translations[selectedLocale.lang]
                                .properties
                            "
                            label="Properties"
                            :error-messages="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.properties'
                              ]
                            "
                            @keyup="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.properties'
                              ] = ''
                            "
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <CustomFieldComponent
                            v-if="
                              formModel.translations[selectedLocale.lang] &&
                              formModel.translations[selectedLocale.lang]
                                .custom_fields
                            "
                            customFieldName="attachments"
                            :value="
                              formModel.translations[selectedLocale.lang]
                                .custom_fields.attachments || ''
                            "
                            :config="{
                              type: 'media_selector',
                              maxFiles: -1,
                              inputFields: [
                                {
                                  name: 'title',
                                  label: 'FORM_INPUT_NAMES.title',
                                  type: 'text',
                                },
                                {
                                  name: 'description',
                                  label: 'FORM_INPUT_NAMES.description',
                                  type: 'text',
                                },
                              ],
                              selectButtonText: $t(
                                'FORM_INPUT_NAMES.select_pdt'
                              ),

                              initialColumnClass:
                                'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                            }"
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                          >
                          </CustomFieldComponent>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        v-model="formpriceListDataValid"
                        ref="formPriceListData"
                      >
                        <v-row>
                          <v-col cols="12" sm="2" md="2">
                            <v-select
                              :id="dynamicID"
                              v-model="priceListData.priceList"
                              :items="filteredPriceListCollection"
                              item-text="name"
                              item-value="id"
                              label="Price list"
                              return-object
                              :rules="requiredRules"
                              :change="handleChangePriceList()"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.currency_id"
                              label="Currency"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <div class="d-flex flex-row">
                              <div>
                                <!-- :items="itemInputData.item.default_quantities" -->
                                <!-- v-if="itemInputData" -->
                                <v-select
                                  v-model="priceListData.quantity"
                                  :items="computedQuantities"
                                  label="Quantity"
                                  :rules="requiredRules"
                                  :id="dynamicID"
                                  :disabled="isEditedItemData"
                                />
                              </div>

                              <div
                                class="pa-1 mt-4"
                                style="white-space: nowrap"
                              >
                                {{ priceListData.quantityUnitTranslated }}
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.price_net"
                              label="Price net"
                              type="number"
                              :rules="priceRules"
                              v-on:input="handleCalculateBrutto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.vat_name"
                              label="Default tax name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.vat_value"
                              label="Default tax rate"
                              type="number"
                              :rules="priceRules"
                              v-on:input="handleCalculateBrutto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.price_gross"
                              label="Price gross"
                              type="number"
                              :rules="priceRules"
                              v-on:input="handleCalculateNetto"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              :id="dynamicID"
                              v-model="priceListData.landing_url"
                              label="Landing URL"
                            ></v-text-field>
                          </v-col> -->
                          <v-col cols="12" sm="2" md="2">
                            <v-btn
                              color="success"
                              @click="handleAddPriceListData"
                              class="ma-1"
                            >
                              Add
                            </v-btn>
                            <v-btn
                              class="ma-1"
                              @click="handleCancelPriceListData"
                              :disabled="!cancelPriceListData"
                            >
                              Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-data-table
                            dense
                            :headers="headersPriceList"
                            :items="formModel.price_lists"
                            item-key="name"
                            class="elevation-1"
                            :disabled="isEditedPriceListData"
                          >
                            <template
                              v-slot:item.quantityUnitTranslated="{ item }"
                            >
                              {{
                                $store.getters.getQuantityUnitByID(
                                  formModel.quantity_unit_id
                                ).name
                              }}
                            </template>
                            <template v-slot:item.id="{ item }">
                              {{ getPriceListById(item.price_list_id).name }}
                            </template>
                            <template v-slot:item.currency_id="{ item }">
                              {{
                                getPriceListById(item.price_list_id).currency_id
                              }}
                            </template>
                            <!-- <template v-slot:item.url="{ item }">
                              <a :href="item.landing_url" target="_blank">{{
                                item.landing_url
                              }}</a>
                            </template> -->
                            <template v-slot:item.price_net="{ item }">
                              {{
                                $helpers.getNicePrice(
                                  item.price_net,
                                  getPriceListById(item.price_list_id)
                                    .currency_id
                                )
                              }}
                            </template>
                            <template v-slot:item.price_gross="{ item }">
                              {{
                                $helpers.getNicePrice(
                                  item.price_gross,
                                  getPriceListById(item.price_list_id)
                                    .currency_id
                                )
                              }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="handleEditPriceListItem(item)"
                                :disabled="isEditedPriceListData"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                v-if="permissionCan('delete')"
                                small
                                @click="handleDeletePriceListItem(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        v-model="componentData.formDataValid"
                        ref="componentFormData"
                      >
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-autocomplete
                              v-model="componentInputData.item"
                              :items="itemCollectionWithDefaultQuantities"
                              item-text="ReferenceName"
                              label="Search item"
                              return-object
                              :disabled="isEditedItemData"
                              :rules="requiredRules"
                            >
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              label="Quantity"
                              v-model="componentInputData.quantity"
                              type="number"
                              min="0"
                              :rules="[
                                (value) => {
                                  return maxQuantityRules(value);
                                },
                              ]"
                              :id="dynamicID"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              label="Quantity unit"
                              :value="
                                getQuantityUnitNameByid(
                                  componentInputData.item.quantity_unit_id
                                )
                              "
                              disabled
                              :id="dynamicID"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              label="No. of pcs"
                              type="number"
                              min="0"
                              v-model="componentInputData.num_of_pcs"
                              :id="dynamicID"
                              :rules="requiredRules"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="5" md="5">
                            <v-btn
                              color="success"
                              @click="handleAddComponent"
                              class="ma-1"
                            >
                              Add
                            </v-btn>
                            <v-btn
                              class="ma-1"
                              @click="handleCancelComponentData"
                              :disabled="!componentData.cancelItemData"
                            >
                              Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Search item"
                            v-model="componentData.search"
                            :id="dynamicID"
                            :disabled="cancelItemData"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-data-table
                            :headers="componentDataTableHeader"
                            :items="formModel.components"
                            :search="componentData.search"
                          >
                            <template v-slot:item.quantityUnit="{ item }">
                              {{
                                getQuantityUnitNameByid(
                                  item.item.quantity_unit_id
                                )
                              }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="handleEditComponentItem(item)"
                                :disabled="componentData.cancelItemData"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                small
                                @click="handleDeleteComponentItem(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        v-model="formAttachmentValid"
                        ref="formAttachment"
                      >
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              :id="dynamicID"
                              v-model="attachmentData.name"
                              label="Name"
                              :rules="requiredRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="7" md="7">
                            <v-text-field
                              :id="dynamicID"
                              v-model="attachmentData.url"
                              label="URL"
                              :rules="requiredRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-btn
                              color="success"
                              @click="handleAddAttachmentData"
                              class="ma-1"
                            >
                              Add
                            </v-btn>
                            <v-btn
                              class="ma-1"
                              @click="handleCancelAttachmentData"
                              :disabled="!cancelAttachmentData"
                            >
                              Cancel
                            </v-btn>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="attachmentDataTableHeader"
                              :items="formModel.attachments"
                            >
                              <!-- <template v-slot:item.url="{ item }">
                                <a v-bind:href="item.url" target="blank">
                                  {{ item.url }}
                                </a>
                              </template> -->
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="handleEditAttachmentItem(item)"
                                >
                                  mdi-pencil
                                </v-icon>
                                <v-icon
                                  small
                                  @click="handleDeleteAttachment(item)"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form v-model="webContentsValid" ref="formWebContent">
                        <v-row>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_name_1
                              "
                              label="Name 1"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_name_1'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_name_1'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .slug_1
                              "
                              label="Slug 1"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.slug_1'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.slug_1'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_name_2
                              "
                              label="Name 2"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_name_2'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_name_2'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .slug_2
                              "
                              label="Slug 2"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.slug_2'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.slug_2'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-spacer></v-spacer>
                          <v-col cols="12" sm="8" md="8">
                            <treeselect
                              :multiple="true"
                              :flat="true"
                              :options="webCategoriesTree"
                              :default-expand-level="0"
                              placeholder="Web Category"
                              v-model="formModel.web_categories"
                            />
                            <v-checkbox
                              v-model="formModel.details_page"
                              color="primary"
                              label="Item webpage"
                              hide-details
                            ></v-checkbox>

                            <!-- <v-autocomplete
                              v-if="formModel"
                              v-model="formModel.web_categories"
                              :items="webCategories"
                              item-text="name"
                              item-value="id"
                              label="Web Categories"
                              chips
                              clearable
                              deletable-chips
                              multiple
                              :id="dynamicID"
                            ></v-autocomplete> -->
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              v-if="formModel"
                              v-model="formModel.websites"
                              :items="webSiteCollection"
                              chips
                              clearable
                              deletable-chips
                              multiple
                              label="Select websites"
                              item-text="name"
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            Description 1
                            <ckeditor
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_description_1
                              "
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_description_1'
                                ]
                              "
                              @input="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_description_1'
                                ] = ''
                              "
                              :config="editorConfig"
                            ></ckeditor>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            Description 2
                            <ckeditor
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_description_2
                              "
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_description_2'
                                ]
                              "
                              @input="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_description_2'
                                ] = ''
                              "
                              :config="editorConfig1"
                            ></ckeditor>
                          </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-col cols="12">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_seo_title_1
                              "
                              label="SEO title 1"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_title_1'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_title_1'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              :id="dynamicID"
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_seo_title_2
                              "
                              label="SEO title 2"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_title_2'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_title_2'
                                ] = ''
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_seo_description_1
                              "
                              label="SEO description 1"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_descriptio_1'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_description_1'
                                ] = ''
                              "
                            ></v-textarea>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="
                                formModel.translations[selectedLocale.lang]
                                  .web_seo_description_2
                              "
                              label="SEO description 2"
                              :error-messages="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_description_2'
                                ]
                              "
                              @keyup="
                                messages[
                                  'translations.' +
                                    selectedLocale.lang +
                                    '.web_seo_description_2'
                                ] = ''
                              "
                            ></v-textarea>
                          </v-col>
                        </v-row> -->
                        <v-divider></v-divider>
                        <h5>Images - 1</h5>

                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="card_image_1"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.card_image_1 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 1,
                                inputFields: [
                                  {
                                    name: 'alt_text',
                                    label: 'FORM_INPUT_NAMES.alt_text',
                                    type: 'text',
                                  },
                                  {
                                    name: 'title',
                                    label: 'FORM_INPUT_NAMES.title',
                                    type: 'text',
                                  },
                                  {
                                    name: 'description',
                                    label: 'FORM_INPUT_NAMES.description',
                                    type: 'text',
                                  },
                                ],
                                selectButtonText: $t(
                                  'FORM_INPUT_NAMES.card_image'
                                ),
                                resolution: '85x443',
                                initialColumnClass:
                                  'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="100"
                            >
                            </CustomFieldComponent>

                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="gallery_1"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.gallery_1 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 30,
                                inputFields: [
                                  {
                                    name: 'alt_text',
                                    label: 'FORM_INPUT_NAMES.alt_text',
                                    type: 'text',
                                  },
                                  {
                                    name: 'title',
                                    label: 'FORM_INPUT_NAMES.title',
                                    type: 'text',
                                  },
                                  {
                                    name: 'description',
                                    label: 'FORM_INPUT_NAMES.description',
                                    type: 'text',
                                  },
                                ],
                                selectButtonText: $t(
                                  'FORM_INPUT_NAMES.gallery'
                                ),
                                // resolution: '1032x683',
                                initialColumnClass:
                                  'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="100"
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>

                        <v-divider></v-divider>
                        <h5>Images - 2</h5>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="card_image_2"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.card_image_2 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 1,
                                inputFields: [
                                  {
                                    name: 'alt_text',
                                    label: 'FORM_INPUT_NAMES.alt_text',
                                    type: 'text',
                                  },
                                  {
                                    name: 'title',
                                    label: 'FORM_INPUT_NAMES.title',
                                    type: 'text',
                                  },
                                  {
                                    name: 'description',
                                    label: 'FORM_INPUT_NAMES.description',
                                    type: 'text',
                                  },
                                ],
                                selectButtonText: $t(
                                  'FORM_INPUT_NAMES.card_image'
                                ),
                                // resolution: '85x443',
                                initialColumnClass:
                                  'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="100"
                            >
                            </CustomFieldComponent>

                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="gallery_2"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.gallery_2 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 30,
                                inputFields: [
                                  {
                                    name: 'alt_text',
                                    label: 'FORM_INPUT_NAMES.alt_text',
                                    type: 'text',
                                  },
                                  {
                                    name: 'title',
                                    label: 'FORM_INPUT_NAMES.title',
                                    type: 'text',
                                  },
                                  {
                                    name: 'description',
                                    label: 'FORM_INPUT_NAMES.description',
                                    type: 'text',
                                  },
                                ],
                                selectButtonText: $t(
                                  'FORM_INPUT_NAMES.gallery'
                                ),
                                resolution: '528x704',
                                initialColumnClass:
                                  'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="100"
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>

                        <v-divider></v-divider>
                        <h5>SEO - 1</h5>
                        <v-row>
                          <v-col cols="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              :config="{
                                type: 'text',
                                label: 'FORMS.seo_title',
                              }"
                              customFieldName="seoTitle_1"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.seoTitle_1
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="60"
                            >
                            </CustomFieldComponent>
                          </v-col>
                          <v-col cols="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              :config="{
                                type: 'textarea',
                                label: 'FORMS.seo_description',
                              }"
                              customFieldName="seoDescription_1"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.seoDescription_1
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="150"
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="8" md="8">
                            <v-row>
                              <!-- <v-col cols="12" sm="6" md="6">
                                <CustomFieldComponent
                                  v-if="
                                    formModel.translations[
                                      selectedLocale.lang
                                    ] &&
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields
                                  "
                                  :config="{
                                    type: 'text',
                                    label: 'FORM_INPUT_NAMES.og_site_name',
                                  }"
                                  customFieldName="og_site_name_1"
                                  :value="
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields.og_site_name_1 || ''
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col> -->
                              <v-col cols="12" sm="6" md="6" class="pt-0 mt-3">
                                <CustomFieldComponent
                                  v-if="
                                    formModel.translations[
                                      selectedLocale.lang
                                    ] &&
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields
                                  "
                                  :config="{
                                    type: 'text',
                                    label: 'FORM_INPUT_NAMES.og_title',
                                  }"
                                  customFieldName="og_title_1"
                                  :value="
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields.og_title_1 || ''
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" class="pt-0 mt-0">
                                <CustomFieldComponent
                                  v-if="formModel && formModel.custom_fields"
                                  :config="{
                                    type: 'multi_select',
                                    multiple: false,
                                    label: $t('FORM_INPUT_NAMES.og_type'),

                                    items: og_types,
                                  }"
                                  customFieldName="og_type_1"
                                  :value="
                                    formModel.custom_fields.og_type_1 ||
                                    'website'
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <CustomFieldComponent
                                  v-if="
                                    formModel.translations[
                                      selectedLocale.lang
                                    ] &&
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields
                                  "
                                  :config="{
                                    type: 'textarea',
                                    label: 'FORM_INPUT_NAMES.og_description',
                                  }"
                                  customFieldName="og_description_1"
                                  :value="
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields.og_description_1 || ''
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="og_image_1"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.og_image_1 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 1,
                                selectButtonText: 'FORM_INPUT_NAMES.og_image',
                                initialColumnClass:
                                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                                resolution: '1200x630',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h5>SEO - 2</h5>
                        <v-row>
                          <v-col cols="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              :config="{
                                type: 'text',
                                label: 'FORMS.seo_title',
                              }"
                              customFieldName="seoTitle_2"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.seoTitle_2
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="60"
                            >
                            </CustomFieldComponent>
                          </v-col>
                          <v-col cols="12">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              :config="{
                                type: 'textarea',
                                label: 'FORMS.seo_description',
                              }"
                              customFieldName="seoDescription_2"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.seoDescription_2
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                              :calcLength="true"
                              :maxLength="150"
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="8" md="8">
                            <v-row>
                              <v-col cols="12" sm="6" md="6" class="pt-0 mt-3">
                                <CustomFieldComponent
                                  v-if="
                                    formModel.translations[
                                      selectedLocale.lang
                                    ] &&
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields
                                  "
                                  :config="{
                                    type: 'text',
                                    label: 'FORM_INPUT_NAMES.og_title',
                                  }"
                                  customFieldName="og_title_2"
                                  :value="
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields.og_title_2 || ''
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" class="pt-0 mt-0">
                                <CustomFieldComponent
                                  v-if="formModel && formModel.custom_fields"
                                  :config="{
                                    type: 'multi_select',
                                    multiple: false,
                                    label: $t('FORM_INPUT_NAMES.og_type'),

                                    items: og_types,
                                  }"
                                  customFieldName="og_type_2"
                                  :value="
                                    formModel.custom_fields.og_type_2 ||
                                    'website'
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <CustomFieldComponent
                                  v-if="
                                    formModel.translations[
                                      selectedLocale.lang
                                    ] &&
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields
                                  "
                                  :config="{
                                    type: 'textarea',
                                    label: 'FORM_INPUT_NAMES.og_description',
                                  }"
                                  customFieldName="og_description_2"
                                  :value="
                                    formModel.translations[selectedLocale.lang]
                                      .custom_fields.og_description_2 || ''
                                  "
                                  @handleChangeCustomFieldValue="
                                    handleChangeCustomFieldValue
                                  "
                                >
                                </CustomFieldComponent>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <CustomFieldComponent
                              v-if="
                                formModel.translations[selectedLocale.lang] &&
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields
                              "
                              customFieldName="og_image_2"
                              :value="
                                formModel.translations[selectedLocale.lang]
                                  .custom_fields.og_image_2 || ''
                              "
                              :config="{
                                type: 'media_selector',
                                maxFiles: 1,
                                selectButtonText: 'FORM_INPUT_NAMES.og_image',
                                initialColumnClass:
                                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                                resolution: '1200x630',
                              }"
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-form>
        <!-- <pre>
          {{ formModel }}
        </pre> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && isNotEditedItem) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          :disabled="
            isEditedPriceListData ||
            componentData.cancelItemData ||
            cancelAttachmentData
          "
          @click="handleSaveModalForm"
        >
          Save
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="dialogDeletePriceList" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeletePriceList"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="deletePriceListItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteAttachment" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this attachment?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeleteAttachment"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteAttachmentConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="componentData.dialogDeleteItem" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeleteComponent"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="deleteComponentItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import { ENDPOINT } from "./Items";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { categoryTreeMixins } from "@/view/mixins/categoryTreeMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = [
  "name",
  "description",
  "properties",
  "web_name_1",
  "web_name_2",
  "web_description_1",
  "web_description_2",
  // "web_seo_title_1",
  // "web_seo_title_2",
  // "web_seo_description_1",
  // "web_seo_description_2",
  "slug_1",
  "slug_2",
];

export const initialPriceListData = {
  id: null,
  vat_value: null,
  vat_name: null,
  price_net: null,
  price_gross: null,
  priceList: {},
  landing_url: "",
  quantity: null,
  quantityUnitTranslated: "",
};

const initialComponentInputData = {
  id: null,
  item: {},
  name: "",
  reference: "",
  quantity: null,
  num_of_pcs: null,
};

const initialComponentData = {
  id: null,
  itemToDelete: {},
  dialogDeleteItem: false,
  formDataValid: false,
  cancelItemData: null,
  search: "",
};

const initialAttachmentData = {
  name: "",
  url: "",
};

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "gallery_1",
  "gallery_2",
  "card_image_1",
  "card_image_2",
  "seoTitle_1",
  "seoDescription_1",
  "seoTitle_2",
  "seoDescription_2",
  "og_title_1",
  "og_title_2",
  "og_description_1",
  "og_description_2",
  "og_url_1",
  "og_url_2",
  "og_site_name_1",
  "og_site_name_2",
  "og_image_1",
  "og_image_2",
  "attachments",
];

export const INITIAL_CUSTOM_FIELDS = {
  related_items: [],
};

// export const initialFormData = {
export const initialFormData = () => ({
  id: null,
  status: 1,
  reference: "",
  category_id: null,
  quantity_unit_id: null,
  manufacture: 0,
  sale: 0,
  service: 0,
  translations: {},
  price_lists: [],
  components: [],
  default_quantities: [],
  maxDefaultQuantity: 0,
  standard_time: null,
  unit_weight: 0,
  attachments: [],
  websites: [],
  web_categories: [],
  custom_fields: Object.assign({}, INITIAL_CUSTOM_FIELDS),
  cutting_id: null,
  details_page: false,
});

export default {
  name: "ItemModalForm",
  props: [
    "modalData",
    "editedItemId",
    "permissions",
    "statuses",
    "categories",
    "webCategories",
    "quantityUnitCollection",
    "priceListCollection",
    "itemCollection",
    "itemCollectionWithDefaultQuantities",
    "defaultTab",
  ],
  components: { CustomFieldComponent, Treeselect, draggable },
  mixins: [formModelMixins, customFieldMixins, categoryTreeMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      priceListData: Object.assign({}, initialPriceListData),
      isEditedPriceListData: false,
      priceListTodelete: {},
      formValid: false,
      formAttachmentValid: false,
      dialogDeletePriceList: false,
      dialogDeleteAttachment: false,
      formpriceListDataValid: false,
      attachmentTodelete: null,
      cancelPriceListData: null,
      cancelAttachmentData: null,
      componentInputData: Object.assign({}, initialComponentInputData),
      componentData: Object.assign({}, initialComponentData),
      attachmentData: Object.assign({}, initialAttachmentData),
      componentDataTableHeader: [
        { text: "Reference", value: "reference" },
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        { text: "No. of pcs", value: "num_of_pcs" },
        { text: "Actions", value: "actions" },
      ],
      attachmentDataTableHeader: [
        { text: "Name", value: "name" },
        { text: "URL", value: "url" },
        { text: "Actions", value: "actions" },
      ],
      messages: {},
      nameRules: [
        (v) => !!v || "Item name is required",
        (v) => (!!v && v.length > 3) || "Item name must be min 4 characters",
      ],

      referenceRules: [
        (v) => !!v || "Reference is required",
        (v) => v.length > 4 || "Item name must be min 5 characters",
        (v) => v.length < 21 || "Item name must be max 20 characters",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      priceRules: [
        // (v) => !!v || "Price is required",
        (v) => v >= -1 || "The value must be min 0",
      ],

      headersPriceList: [
        {
          text: "Price list",
          align: "start",
          value: "id",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Quantity unit",
          value: "quantityUnitTranslated",
        },
        { text: "Currency", value: "currency_id" },
        { text: "Price net", value: "price_net" },
        { text: "Default tax name", value: "vat_name" },
        { text: "Default tax rate", value: "vat_value" },
        { text: "Price gross", value: "price_gross" },
        // { text: "Landing URL", value: "landing_url" },
        // { text: "Landing URL", value: "url" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },

      editorConfig1: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
      custom_fields: Object.assign({}, INITIAL_CUSTOM_FIELDS),
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,
      og_types: ["website", "article"],
      cloneItem: false,

      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
    };
  },
  computed: {
    ...mapGetters(["webSiteCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    isNotEditedItem() {
      if (this.formModel.id) {
        return 0;
      } else {
        return 1;
      }
    },
    formTitle() {
      if (this.cloneItem) return "Clone Item";
      return this.formModel.id ? "Edit item" : "New item";
    },

    filteredItemCollection() {
      let itemIds = this.formModel.components.map((item) => item.id);
      return this.itemCollection.filter((item) => {
        return !itemIds.includes(item.id);
      });
    },

    filteredPriceListCollection() {
      let priceListIds = this.formModel.price_lists.map(
        (item) => item.price_list_id
      );

      var counts = {};
      priceListIds.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });

      return this.priceListCollection.filter((item) => {
        return this.formModel.default_quantities.length != counts[item.id];
      });
    },

    computedQuantities() {
      if (
        this.formModel.default_quantities.length == 0 ||
        !this.priceListData.id
      ) {
        return [];
      }

      let usedQuantities = this.formModel.price_lists
        .filter((i) => {
          return i.price_list_id == this.priceListData.id;
        })
        .map((i) => {
          return 1 * i.quantity;
        });

      return this.formModel.default_quantities.filter((q) => {
        return !usedQuantities.includes(1 * q);
      });
    },
    initialFormData() {
      return initialFormData;
    },
    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },

    // webCategoriesTree() {
    //   return this.webCategories
    //     .filter((item) => !item.web_category_id)
    //     .map((item) => {
    //       let category = {};
    //       category.id = item.id;
    //       category.label = item.name;
    //       if (item.web_categories.length > 0) {
    //         category.children = this.getWebCategoryChildren(
    //           item.web_categories
    //         );
    //       }
    //       return category;
    //     });
    // },
    // categoryTree() {
    //   return this.categories
    //     .filter((item) => !item.category_id)
    //     .map((item) => {
    //       let category = {};
    //       category.id = item.id;
    //       category.label = item.name;
    //       if (item.categories.length > 0) {
    //         category.children = this.getCategoryChildren(item.categories);
    //       }
    //       return category;
    //     });
    // },
  },
  watch: {
    "priceListData.priceList": {
      handler: function (value) {
        if (value) {
          this.priceListData.id = value.id;
          this.priceListData.vat_value = value.vat_value;
          this.priceListData.vat_name = value.vat_name;
          this.priceListData.currency_id = value.currency_id;
          this.handleCalculateBrutto();
        } else {
          this.priceListData = Object.assign({}, initialPriceListData);
        }
      },
      deep: true,
    },

    editedItemId(value) {
      this.selectedLocale = i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      });
      this.priceListData = Object.assign({}, initialPriceListData);
      this.isEditedPriceListData = false;
      this.cancelPriceListData = false;
      this.cancelAttachmentData = false;
      if (value) {
        this.loader = true;
        ApiService.get(ENDPOINT + value)
          .then(({ data }) => {
            data.components = data.components.map((item) => {
              item.item = this.itemCollection.find(
                (ic) => ic.id == item.component_id
              );
              item.reference = item.item.reference;

              if (item.item.translations[this.selectedLocale.lang]) {
                item.name =
                  item.item.translations[this.selectedLocale.lang].name;
              } else {
                item.name =
                  item.item.translations[
                    Object.keys(item.item.translations)[0]
                  ].name;
              }
              return item;
            });
            if (!data.attachments) data.attachments = [];
            data.standard_time = data.standard_time / 3600;
            if (!data.custom_fields) {
              data.custom_fields = initialFormData().custom_fields;
            }

            data.websites = data.websites.map((item) => item.website_id);
            this.formModel = Object.assign({}, data);

            this.setTranslatedAttributes();
            this.formModel.customFields = data.custom_fields;

            this.resetErrorMessages();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.formModel = Object.assign({}, initialFormData());
        this.cancelPriceListData = null;
        this.cancelAttachmentData = null;
        this.componentInputData = Object.assign({}, initialComponentInputData);
        this.priceListData = Object.assign({}, initialPriceListData);
        this.attachmentData = Object.assign({}, initialAttachmentData);
        this.setTranslatedAttributes();

        this.resetErrorMessages();
      }
    },

    "formModel.default_quantities": {
      handler: function (oldval, val) {
        if (val && val.length > 0 && oldval.length != val.length) {
          if (isNaN(val[val.length - 1])) {
            this.$nextTick(() =>
              this.formModel.default_quantities.pop().sort()
            );
          }
          this.$nextTick(() =>
            this.formModel.default_quantities.sort((a, b) => a - b)
          );
        }
      },
    },

    "componentInputData.item": {
      handler: function (val) {
        if (val.default_quantities) {
          this.formModel.maxDefaultQuantity = Math.max(
            ...val.default_quantities
          );
        } else {
          this.formModel.maxDefaultQuantity = 0;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["fetchWebSite"]),
    maxQuantityRules(value) {
      if (value && value > this.formModel.maxDefaultQuantity)
        return "Max quantity value is " + this.formModel.maxDefaultQuantity;
      if (!value) return "Quantity is required";
      return true;
    },

    // [
    //     (v) => !!v || "Item is required",
    //     (v) =>
    //       (!!v && v.length <= this.formModel.maxDefaultQuantity) ||
    //       "Item must be max " + this.formModel.maxDefaultQuantity,
    //   ],

    handleCloseModalForm() {
      this.cancelPriceListData = null;
      this.componentInputData = Object.assign({}, initialComponentInputData);
      this.priceListData = Object.assign({}, initialPriceListData);
      this.formModel = Object.assign({}, initialFormData());
      this.cloneItem = false;
      this.resetErrorMessages();
      this.$emit("closeModalForm");
      this.selectedTab = 0;
    },

    handleAddPriceListData() {
      this.$refs.formPriceListData.validate();
      if (this.formpriceListDataValid) {
        this.priceListData.price_gross = this.getFormattedPrice(
          this.priceListData.price_gross
        );
        this.priceListData.price_net = this.getFormattedPrice(
          this.priceListData.price_net
        );
        this.priceListData.price_list_id = this.priceListData.id;

        this.formModel.price_lists.unshift(
          Object.assign({}, this.priceListData)
        );
        this.priceListData = Object.assign({}, this.initialPriceListData);
        this.$refs.formPriceListData.resetValidation();
        this.isEditedPriceListData = false;
        this.cancelPriceListData = false;
      }
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();
      if (!this.formValid) return;

      // console.error("this.formValid", this.formValid);
      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;

        /**Delete unused translations */
        let translations = {};
        i18nService.languages.forEach((item) => {
          let langCode = item.lang;
          if (
            model.translations[langCode] &&
            model.translations[langCode].name
          ) {
            translations[langCode] = Object.assign(
              {},
              model.translations[langCode]
            );
          }
        });
        if (Object.keys(translations).length != 0) {
          model.translations = translations;
        }
        /**Delete unused translations */
        model.standard_time = model.standard_time * 3600;

        if (model.standard_time == 0) model.standard_time = null;
        if (model.unit_weight == 0) model.unit_weight = null;
        this.loader = true;
        if (model.id) {
          // alert("PUT " + model.id);
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              // console.error(data);
              this.modalData.loading = false;
              this.cloneItem = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, this.formModel));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.modalData.loading = false;
              this.cloneItem = false;
              this.snackbar = true;
              this.formModel.id = data.id;
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.error("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    // setTranslatedAttributes(newItem = false) {
    //   if (newItem) {
    //     this.languages.forEach((language) => {
    //       TRANSLATED_ATRIBUTES.forEach((attribute) => {
    //         this.formModel.translations[language.lang] = {};
    //         this.formModel.translations[language.lang][attribute] = "";
    //       });
    //     });
    //   } else {
    //     this.languages.forEach((language) => {
    //       TRANSLATED_ATRIBUTES.forEach((attribute) => {
    //         if (!this.formModel.translations[language.lang]) {
    //           this.formModel.translations[language.lang] = {};
    //           this.formModel.translations[language.lang][attribute] = "";
    //         }
    //       });
    //     });
    //   }
    // },

    handleDeletePriceListItem(item) {
      this.priceListTodelete = item;
      this.dialogDeletePriceList = true;
    },

    deletePriceListItemConfirm() {
      this.formModel.price_lists.splice(
        this.formModel.price_lists.indexOf(this.priceListTodelete),
        1
      );
      this.closeDeletePriceList();
    },

    closeDeletePriceList() {
      this.priceListTodelete = {};
      this.dialogDeletePriceList = false;
    },

    handleDeleteAttachment(item) {
      this.attachmentTodelete = item;
      this.dialogDeleteAttachment = true;
    },

    deleteAttachmentConfirm() {
      this.formModel.attachments.splice(
        this.formModel.attachments.indexOf(this.attachmentTodelete),
        1
      );
      this.closeDeleteAttachment();
    },

    closeDeleteAttachment() {
      this.attachmentTodelete = {};
      this.dialogDeleteAttachment = false;
    },

    handleEditPriceListItem(item) {
      if (!this.isEditedPriceListData) {
        let priceList = this.getPriceListById(item.price_list_id);
        item.priceList = Object.assign({}, priceList);
        this.priceListData = Object.assign({}, item);
        this.cancelPriceListData = Object.assign({}, item);

        this.priceListData.vat_value = item.vat_value;
        this.priceListData.vat_name = item.vat_name;

        this.formModel.price_lists.splice(
          this.formModel.price_lists.indexOf(item),
          1
        );
        this.isEditedPriceListData = true;
      }
      console.log(item);
    },

    handleCancelPriceListData() {
      this.formModel.price_lists.unshift(
        Object.assign({}, this.cancelPriceListData)
      );
      this.priceListData = Object.assign({}, this.initialPriceListData);
      this.$refs.formPriceListData.resetValidation();
      this.isEditedPriceListData = false;
      this.cancelPriceListData = false;
    },

    handleNew() {
      this.formModel = Object.assign({}, initialFormData());
      this.resetErrorMessages();
      this.priceListData = Object.assign({}, initialPriceListData);
      this.setTranslatedAttributes(true);
      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleCalculateBrutto() {
      if (this.priceListData.price_net) {
        this.priceListData.price_gross = this.getFormattedPrice(
          Number(this.priceListData.price_net) *
            (Number(this.priceListData.vat_value) / 100 + 1)
        );
      }
    },

    handleCalculateNetto() {
      if (this.priceListData.price_gross) {
        this.priceListData.price_net = this.getFormattedPrice(
          Number(this.priceListData.price_gross) /
            (Number(this.priceListData.vat_value) / 100 + 1)
        );
      }
    },

    getPriceListById(id) {
      return this.priceListCollection.find((priceList) => {
        return priceList.id == id;
      });
    },

    resetErrorMessages() {
      this.selectedTab = 0;
      let errorData = Object.assign({}, initialFormData());
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    getQuantityUnitNameByid(id = null) {
      if (!id || !this.quantityUnitCollection.find((item) => item.id == id))
        return "";
      return this.quantityUnitCollection.find((item) => item.id == id).name;
    },

    handleEditComponentItem(item) {
      if (!this.componentData.cancelItemData) {
        // let priceList = this.getPriceListById(item.id);
        // item.priceList = Object.assign({}, priceList);
        // this.priceListData = Object.assign({}, item);
        this.componentData.cancelItemData = Object.assign({}, item);
        this.componentInputData = Object.assign({}, item);
        this.formModel.components.splice(
          this.formModel.components.indexOf(item),
          1
        );
        // this.isEditedPriceListData = true;
      }
    },

    handleCancelComponentData() {
      this.formModel.components.unshift(
        Object.assign({}, this.componentData.cancelItemData)
      );
      this.componentInputData = Object.assign({}, initialComponentInputData);
      this.$refs.componentFormData.resetValidation();
      this.componentData.cancelItemData = null;
    },

    handleAddComponent() {
      this.$refs.componentFormData.validate();
      if (this.componentData.formDataValid) {
        this.componentInputData.reference =
          this.componentInputData.item.reference;
        this.componentInputData.name = this.componentInputData.item.name;

        this.componentInputData.component_id = this.componentInputData.item.id;
        // this.componentInputData.id = this.formModel.id;

        this.formModel.components.unshift(
          Object.assign({}, this.componentInputData)
        );
        // console.log(this.formModel.components);
        this.componentInputData = Object.assign({}, initialComponentInputData);
        this.$refs.componentFormData.resetValidation();
        this.componentData.cancelItemData = null;
      }
    },

    getFormattedPrice(price, currency = this.priceListData.currency_id) {
      if (price == 0 || !price || isNaN(price)) return 0;

      if (currency == "HUF") {
        return Math.round(price);
      }

      return (1 * price).toFixed(2);
    },

    handleDeleteComponentItem(item) {
      this.componentData.itemToDelete = item;
      this.componentData.dialogDeleteItem = true;
    },

    deleteComponentItemConfirm() {
      this.formModel.components.splice(
        this.formModel.components.indexOf(this.componentData.itemToDelete),
        1
      );
      this.closeDeleteComponent();
    },

    closeDeleteComponent() {
      this.componentData.itemToDelete = {};
      this.componentData.dialogDeleteItem = false;
    },

    handleChangePriceList() {
      // console.log(this.formModel, this.priceListData);
      if (!this.computedQuantities || this.computedQuantities.length == 0) {
        this.priceListData.quantity = null;
      } else {
        // this.priceListData.quantity = this.computedQuantities[0];
        if (!this.priceListData.quantity)
          this.priceListData.quantity = this.computedQuantities[0];

        let qu = this.$store.getters.getQuantityUnitByID(
          this.formModel.quantity_unit_id
        );

        if (typeof qu == "object") {
          this.priceListData.quantityUnitTranslated = qu.name;
        }
      }
    },

    handleAddAttachmentData() {
      this.$refs.formAttachment.validate();
      if (this.formAttachmentValid) {
        this.formModel.attachments.unshift(this.attachmentData);
        this.cancelAttachmentData = null;
        this.attachmentData = Object.assign({}, initialAttachmentData);
        this.$refs.formAttachment.resetValidation();
      }
    },

    handleCancelAttachmentData() {
      this.formModel.attachments.unshift(
        Object.assign({}, this.cancelAttachmentData)
      );

      this.attachmentData = Object.assign({}, this.initialAttachmentData);
      // this.$refs.formPriceListData.resetValidation();
      this.cancelAttachmentData = false;
    },

    handleEditAttachmentItem(item) {
      this.attachmentData = Object.assign({}, item);
      this.cancelAttachmentData = Object.assign({}, item);
      this.formModel.attachments = this.formModel.attachments.filter((i) => {
        return i.name != item.name && i.url != item.url;
      });
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    remove(item) {
      this.formModel.default_quantities.splice(
        this.formModel.default_quantities.indexOf(item),
        1
      );
      this.formModel.default_quantities = [
        ...this.formModel.default_quantities,
      ];
    },

    removeRelated(item) {
      this.formModel.custom_fields.related_items = [
        ...this.formModel.custom_fields.related_items.filter(
          (i) => i != item.id
        ),
      ];
    },

    geCuttingItems() {
      return this.itemCollection.filter(
        (item) =>
          item.category_id ==
          process.env.VUE_APP_RELATED_CUTTING_SERVICE_CATEGORY_ID
      );
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "inventory.item." + action);
    },

    handleDeleteItem() {
      this.$emit("handleDeleteItem", this.formModel);
    },

    // getWebCategoryById(id) {
    //   let webCategory = this.webCategories.find((item) => item.id == id);
    //   let webCategoryTree = {
    //     id: webCategory.id,
    //     label: webCategory.name,
    //   };
    //   if (webCategory.web_categories && webCategory.web_categories.length > 0) {
    //     webCategoryTree.children = this.getWebCategoryChildren(
    //       webCategory.web_categories
    //     );
    //   }
    //   return webCategoryTree;
    // },

    // getWebCategoryChildren(webcategoryIds = []) {
    //   return webcategoryIds.map((id) => this.getWebCategoryById(id));
    // },

    // getCategoryById(id) {
    //   let category = this.categories.find((item) => item.id == id);
    //   let categoryTree = {
    //     id: category.id,
    //     label: category.name,
    //   };
    //   if (category.categories && category.categories.length > 0) {
    //     categoryTree.children = this.getCategoryChildren(category.categories);
    //   }
    //   return categoryTree;
    // },

    // getCategoryChildren(categoryIds = []) {
    //   return categoryIds.map((id) => this.getCategoryById(id));
    // },

    handleCloneItem() {
      this.formModel.id = null;
      this.formModel.reference += "_clone";
      this.languages.forEach((language) => {
        this.formModel.translations[language.lang].slug_1 = null;
        this.formModel.translations[language.lang].slug_2 = null;
      });
      this.formModel.deletable = true;
      this.cloneItem = true;
    },

    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },

    change: function (value) {
      if (value.removed) {
        // insert
        this.formModel.custom_fields.related_items.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.formModel.custom_fields.related_items[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.formModel.custom_fields.related_items.splice(
            this.dragged.from,
            1
          );
        // RTL
        else
          this.formModel.custom_fields.related_items.splice(
            this.dragged.from + 1,
            1
          );
      }
    },

    handleChangeReference() {
      this.$nextTick(() => {
        this.formModel.reference = this.formModel.reference.substring(0, 20);
      });
    },
  },

  async mounted() {
    this.fetchWebSite();
  },
};
</script>
